import { TemplateActionTypes } from './constants';

type TemplateAction = { type: string; payload: {} | string };

// common success
export const templateApiResponseSuccess = (actionType: string, data: any): TemplateAction => ({
    type: TemplateActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const templateApiResponseError = (actionType: string, error: string): TemplateAction => ({
    type: TemplateActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createTemplate = (data: object): TemplateAction => ({
    type: TemplateActionTypes.CREATE_TEMPLATE,
    payload: { data },
});

export const getTemplates = (options: object): TemplateAction => ({
    type: TemplateActionTypes.GET_TEMPLATES,
    payload: options,
});

export const getTemplate = (id: string): TemplateAction => ({
    type: TemplateActionTypes.GET_TEMPLATE_BY_ID,
    payload: { id },
});

export const getTemplatesByUser = (user: string): TemplateAction => ({
    type: TemplateActionTypes.GET_TEMPLATES_BY_USER,
    payload: { user },
});

export const updateTemplate = (id: string, data: object): TemplateAction => ({
    type: TemplateActionTypes.UPDATE_TEMPLATE,
    payload: { id, data },
});

export const getAccessByTemplate = (id: string): TemplateAction => ({
    type: TemplateActionTypes.GET_TEMPLATE_ACCESS,
    payload: { id },
});

export const revokeAccessByTemplate = (id: string, data: object): TemplateAction => ({
    type: TemplateActionTypes.REVOKE_TEMPLATE_ACCESS,
    payload: { id, data },
});

export const resetTemplates = (): TemplateAction => ({
    type: TemplateActionTypes.RESET_TEMPLATES,
    payload: {},
});
