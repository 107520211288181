export const TemplateActionTypes = {
    API_RESPONSE_SUCCESS: '@@templates/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@templates/API_RESPONSE_ERROR',

    GET_TEMPLATES: '@@templatess/GET_TEMPLATES',
    GET_TEMPLATE_BY_ID: '@@templates/GET_TEMPLATE_BY_ID',
    GET_TEMPLATES_BY_USER: '@@templatess/GET_TEMPLATES_BY_USER',
    GET_TEMPLATE_ACCESS: '@@templates/GET_TEMPLATE_ACCESS',
    REVOKE_TEMPLATE_ACCESS: '@@templates/REVOKE_TEMPLATE_ACCESS',
    CREATE_TEMPLATE: '@@templates/CREATE_TEMPLATE',
    UPDATE_TEMPLATE: '@@templates/UPDATE_TEMPLATE',
    DELETE_TEMPLATE: '@@templates/DELETE_TEMPLATE',
    RESET_TEMPLATES: '@@templates/RESET_TEMPLATES',
};
