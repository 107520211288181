import { TemplateActionTypes } from './constants';

const INIT_STATE = {
    data: [],
    loading: false,
    error: null,
    updateSuccess: null,
    createSuccess: null,
};

const Template = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TemplateActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TemplateActionTypes.CREATE_TEMPLATE:
                    return { ...state, data: action.payload.data, loading: false, createSuccess: true };
                case TemplateActionTypes.GET_TEMPLATES:
                    return { ...state, data: action.payload.data, loading: false };
                case TemplateActionTypes.GET_TEMPLATE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case TemplateActionTypes.GET_TEMPLATES_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case TemplateActionTypes.UPDATE_TEMPLATE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case TemplateActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TemplateActionTypes.CREATE_TEMPLATE:
                    return { ...state, error: action.payload, loading: false };
                case TemplateActionTypes.GET_TEMPLATES:
                    return { ...state, error: action.payload, loading: false };
                case TemplateActionTypes.GET_TEMPLATE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case TemplateActionTypes.GET_TEMPLATES_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case TemplateActionTypes.UPDATE_TEMPLATE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        case TemplateActionTypes.CREATE_TEMPLATE:
            return { ...state, loading: true };
        case TemplateActionTypes.GET_TEMPLATES:
            return { ...state, loading: true };
        case TemplateActionTypes.GET_TEMPLATE_BY_ID:
            return { ...state, loading: true };
        case TemplateActionTypes.GET_TEMPLATES_BY_USER:
            return { ...state, loading: true };
        case TemplateActionTypes.UPDATE_TEMPLATE:
            return {
                ...state,
                id: action.payload.id,
                data: action.payload.data,
                loading: false,
            };
        case TemplateActionTypes.RESET_TEMPLATES:
            return INIT_STATE;
        default:
            return { ...state, updateSuccess: null, createSuccess: null };
    }
};

export default Template;
